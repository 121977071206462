import React from "react";
import PageHeader from "../components/page-header";
import Seo from "../components/seo";
import Layout from "../layouts/index";

const WhatWeDo = () => {
  return (
    <Layout>
      <Seo title="What we do" />

      <PageHeader title="What we do" />

      <section className="block grid-container narrow" aria-labelledby="#whatWeDoIntro">
        <h2 id="whatWeDoIntro" className="show-for-sr">
          Introduction
        </h2>
        <p>
          With over 40 years’ experience in the motor industry, MotoNovo Finance has helped thousands of customers to
          purchase the car, van, or motorcycle of their dreams. We work with some of the best dealerships across the
          country, offering customers a wide range of products and services to suit their needs.
        </p>
      </section>

      <section className="block grid-container narrow" aria-labelledby="#whatWeDoMotorFinance">
        <h2 id="whatWeDoMotorFinance">Motor Finance</h2>
        <p>
          Thousands of customers choose MotoNovo Finance every week to purchase their next car, van or motorbike. We are
          a secure and highly respected organisation that has been recognised as a market leader for some time. We offer
          a range of finance products to our customers, from HP (Hire Purchase) to PCP (Personal Contract Purchase), and
          digital products such a MotoClick and Quote and Propose to our dealers.
        </p>
        <p>
          We have the expertise to help customers feel the benefits, convenience, and availability of dealer finance,
          whether they are looking for options to suit their budget or are ready to make that dream purchase.
        </p>
      </section>
    </Layout>
  );
};

export default WhatWeDo;
